import Vue from "vue"
import VueRouter from "vue-router"
import store from "store"

Vue.use(VueRouter)

import AdminSignIn from "views/admin/sign-in"
import AdminLayout from "views/admin/layout"
import AdminFirms from "views/admin/firms"
import AdminShowFirm from "views/admin/firms/show"
import AdminEditFirm from "views/admin/firms/edit"
import AdminManufacturers from "views/admin/manufacturers"
import AdminShowManufacturer from "views/admin/manufacturers/show"
import AdminEditManufacturer from "views/admin/manufacturers/edit"
import AdminVideos from "views/admin/videos"
import AdminShowVideo from "views/admin/videos/show"
import AdminEditVideo from "views/admin/videos/edit"
import AdminUsers from "views/admin/users"
import AdminShowUser from "views/admin/users/show"
import AdminEditUser from "views/admin/users/edit"
import AdminFavorites from "views/admin/favorites"
import AdminEditFavorite from "views/admin/favorites/edit"
import AdminCsiIndexEntries from "views/admin/csi-index-entries"
import AdminShowCsiIndexEntry from "views/admin/csi-index-entries/show"
import AdminEditCsiIndexEntry from "views/admin/csi-index-entries/edit"
import AdminSubDivisions from "views/admin/csi-sub-divisions"
import AdminShowSubDivision from "views/admin/csi-sub-divisions/show"
import AdminEditSubDivision from "views/admin/csi-sub-divisions/edit"
import AdminBanners from "views/admin/banners"
import AdminShowBanner from "views/admin/banners/show"
import AdminEditBanner from "views/admin/banners/edit"
import AdminBuildingProducts from "views/admin/building-products"
import AdminShowBuildingProduct from "views/admin/building-products/show"
import AdminEditBuildingProduct from "views/admin/building-products/edit"
import AdminDesignProfessionals from "views/admin/design-professionals"
import AdminShowDesignProfessional from "views/admin/design-professionals/show"
import AdminEditDesignProfessional from "views/admin/design-professionals/edit"
import AdminFeaturedFirms from "views/admin/featured-firms"
import AdminShowFeaturedFirm from "views/admin/featured-firms/show"
import AdminEditFeaturedFirm from "views/admin/featured-firms/edit"
import AdminFeaturedVideos from "views/admin/featured-videos"
import AdminShowFeaturedVideo from "views/admin/featured-videos/show"
import AdminEditFeaturedVideo from "views/admin/featured-videos/edit"
import AdminFeaturedManufacturers from "views/admin/featured-manufacturers"
import AdminShowFeaturedManufacturer from "views/admin/featured-manufacturers/show"
import AdminEditFeaturedManufacturer from "views/admin/featured-manufacturers/edit"
import AdminWeeklyFeaturedManufacturers from "views/admin/weekly-featured-manufacturers"
import AdminShowWeeklyFeaturedManufacturer from "views/admin/weekly-featured-manufacturers/show"
import AdminEditWeeklyFeaturedManufacturer from "views/admin/weekly-featured-manufacturers/edit"
import AdminHeroes from "views/admin/heroes"
import AdminShowHero from "views/admin/heroes/show"
import AdminEditHero from "views/admin/heroes/edit"
import AdminCourses from "views/admin/courses"
import AdminShowCourse from "views/admin/courses/show"
import AdminEditCourse from "views/admin/courses/edit"
import AdminNewsEntries from "views/admin/news-entries"
import AdminShowNewsEntry from "views/admin/news-entries/show"
import AdminEditNewsEntry from "views/admin/news-entries/edit"
import AdminProductTypes from "views/admin/product-types"
import AdminShowProductType from "views/admin/product-types/show"
import AdminEditProductType from "views/admin/product-types/edit"

import AdminEventIssues from "views/admin/event-issues"
import AdminShowEventIssue from "views/admin/event-issues/show"
import AdminEditEventIssue from "views/admin/event-issues/edit"

import AdminEventStories from "views/admin/event-stories"
import AdminShowEventStory from "views/admin/event-stories/show"
import AdminEditEventStory from "views/admin/event-stories/edit"

import AdminEvent from "views/admin/events"
import AdminShowEvent from "views/admin/events/show"
import AdminEditEvent from "views/admin/events/edit"

import AdminNewsletterSubscription from "views/admin/newsletter-subscriptions"
import AdminShowNewsletterSubscription from "views/admin/newsletter-subscriptions/show"
import AdminEditNewsletterSubscription from "views/admin/newsletter-subscriptions/edit"



const routes = [
    { path: "/", name: "home", component: () => import("views/home"), meta: { title: "designguide.com: Building Product Library + Professional Directory", metaTags: [{ name: "description", content: "Home page" }] } },

    /* ADMIN */

    { path: "/admin", name: "admin-dashboard", component: AdminLayout, meta: { layout: "admin", requiresAdminAuth: true, meta: { title: "Admin" } }, children: [
        /* ADMINS */
        {path: '/',
                redirect: to => {
                  return { name: 'admin-heroes', query: { q: to.params.searchText } }
                }},
        { path: "metrics", name: "admin-metrics", component: () => import("views/admin/metrics/index"), meta: { layout: "admin" } },
        { path: "site-user-demographics", name: "admin-side-user-demographics", component: () => import("views/admin/side-user-demographics/index"), meta: { layout: "admin" } },
        { path: "admins/new", name: "admin-new-admin", component: () => import("views/admin/admins/edit"), meta: { layout: "admin" } },
        { path: "admins/:id/edit", name: "admin-edit-admin", component: () => import("views/admin/admins/edit"), meta: { layout: "admin" } },
        { path: "admins/:id", name: "admin-show-admin", component: () => import("views/admin/admins/show"), meta: { layout: "admin" } },
        { path: "admins", name: "admin-admins", component: () => import("views/admin/admins"), meta: { layout: "admin" } },
        /* BANNERS */
        { path: "banners/new", name: "admin-new-banner", component: AdminEditBanner, meta: { layout: "admin" } },
        { path: "banners/:id/edit", name: "admin-edit-banner", component: AdminEditBanner, meta: { layout: "admin" } },
        { path: "banners/:id", name: "admin-show-banner", component: AdminShowBanner, meta: { layout: "admin" } },
        { path: "banners", name: "admin-banners", component: AdminBanners, meta: { layout: "admin" } },
        /* BUILDING-PRODUCTS */
        { path: "building-products/new", name: "admin-new-building-product", component: AdminEditBuildingProduct, meta: { layout: "admin" } },
        { path: "building-products/:id/edit", name: "admin-edit-building-product", component: AdminEditBuildingProduct, meta: { layout: "admin" } },
        { path: "building-products/:id", name: "admin-show-building-product", component: AdminShowBuildingProduct, meta: { layout: "admin" } },
        { path: "building-products", name: "admin-building-products", component: AdminBuildingProducts, meta: { layout: "admin" } },
        
        /* DESIGN-PROFESSIONALS */
        { path: "design-professionals/new", name: "admin-new-design-professional", component: AdminEditDesignProfessional, meta: { layout: "admin" } },
        { path: "design-professionals/:id/edit", name: "admin-edit-design-professional", component: AdminEditDesignProfessional, meta: { layout: "admin" } },
        { path: "design-professionals/:id", name: "admin-show-design-professional", component: AdminShowDesignProfessional, meta: { layout: "admin" } },
        { path: "design-professionals", name: "admin-design-professionals", component: AdminDesignProfessionals, meta: { layout: "admin" } },
        /* CSI-INDEX-ENTRIES */
        { path: "csi-index-entries/new", name: "admin-new-csi-index-entry", component: AdminEditCsiIndexEntry, meta: { layout: "admin" } },
        { path: "csi-index-entries/:id/edit", name: "admin-edit-csi-index-entry", component: AdminEditCsiIndexEntry, meta: { layout: "admin" } },
        { path: "csi-index-entries/:id", name: "admin-show-csi-index-entry", component: AdminShowCsiIndexEntry, meta: { layout: "admin" } },
        { path: "csi-index-entries", name: "admin-csi-index-entries", component: AdminCsiIndexEntries, meta: { layout: "admin" } },
        /* CSI-SUB-DIVISION */
        { path: "csi-sub-divisions/new", name: "admin-new-csi-sub-division", component: AdminEditSubDivision, meta: { layout: "admin" } },
        { path: "csi-sub-divisions/:id/edit", name: "admin-edit-csi-sub-division", component: AdminEditSubDivision, meta: { layout: "admin" } },
        { path: "csi-sub-divisions/:id", name: "admin-show-csi-sub-division", component: AdminShowSubDivision, meta: { layout: "admin" } },
        { path: "csi-sub-divisions", name: "admin-csi-sub-divisions", component: AdminSubDivisions, meta: { layout: "admin" } },
        /* DESIGN-FIRMS */
        { path: "design-firms/new", name: "admin-new-firm", component: AdminEditFirm, meta: { layout: "admin" } },
        { path: "design-firms/:id/edit", name: "admin-edit-firm", component: AdminEditFirm, meta: { layout: "admin" } },
        { path: "design-firms/:id", name: "admin-show-firm", component: AdminShowFirm, meta: { layout: "admin" } },
        { path: "design-firms", name: "admin-firms", component: AdminFirms, meta: { layout: "admin" } },
        /* FAVORITES */
        { path: "favorites/new", name: "admin-new-favorite", component: AdminEditFavorite, meta: { layout: "admin" } },
        { path: "favorites", name: "admin-favorites", component: AdminFavorites, meta: { layout: "admin" } },
        /* FEATURED-VIDEOS */
        { path: "featured-videos/new", name: "admin-new-featured-video", component: AdminEditFeaturedVideo, meta: { layout: "admin" } },
        { path: "featured-videos/:id/edit", name: "admin-edit-featured-video", component: AdminEditFeaturedVideo, meta: { layout: "admin" } },
        { path: "featured-videos/:id", name: "admin-show-featured-video", component: AdminShowFeaturedVideo, meta: { layout: "admin" } },
        { path: "featured-videos", name: "admin-featured-videos", component: AdminFeaturedVideos, meta: { layout: "admin" } },
        /* FEATURED-FIRMS */
        { path: "featured-firms/new", name: "admin-new-featured-firm", component: AdminEditFeaturedFirm, meta: { layout: "admin" } },
        { path: "featured-firms/:id/edit", name: "admin-edit-featured-firm", component: AdminEditFeaturedFirm, meta: { layout: "admin" } },
        { path: "featured-firms/:id", name: "admin-show-featured-firm", component: AdminShowFeaturedFirm, meta: { layout: "admin" } },
        { path: "featured-firms", name: "admin-featured-firms", component: AdminFeaturedFirms, meta: { layout: "admin" } },
        /* FEATURED-MANUFACTURERS */
        { path: "featured-manufacturers/new", name: "admin-new-featured-manufacturer", component: AdminEditFeaturedManufacturer, meta: { layout: "admin" } },
        { path: "featured-manufacturers/:id/edit", name: "admin-edit-featured-manufacturer", component: AdminEditFeaturedManufacturer, meta: { layout: "admin" } },
        { path: "featured-manufacturers/:id", name: "admin-show-featured-manufacturer", component: AdminShowFeaturedManufacturer, meta: { layout: "admin" } },
        { path: "featured-manufacturers", name: "admin-featured-manufacturers", component: AdminFeaturedManufacturers, meta: { layout: "admin" } },
        /* WEEKLY-FEATURED-MANUFACTURERS */
        { path: "weekly-featured-manufacturers/new", name: "admin-new-weekly-featured-manufacturer", component: AdminEditWeeklyFeaturedManufacturer, meta: { layout: "admin" } },
        { path: "weekly-featured-manufacturers/:id/edit", name: "admin-edit-weekly-featured-manufacturer", component: AdminEditWeeklyFeaturedManufacturer, meta: { layout: "admin" } },
        { path: "weekly-featured-manufacturers/:id", name: "admin-show-weekly-featured-manufacturer", component: AdminShowWeeklyFeaturedManufacturer, meta: { layout: "admin" } },
        { path: "weekly-featured-manufacturers", name: "admin-weekly-featured-manufacturers", component: AdminWeeklyFeaturedManufacturers, meta: { layout: "admin" } },
        /* HEROES */
        { path: "heroes/new", name: "admin-new-hero", component: AdminEditHero, meta: { layout: "admin" } },
        { path: "heroes/:id/edit", name: "admin-edit-hero", component: AdminEditHero, meta: { layout: "admin" } },
        { path: "heroes/:id", name: "admin-show-hero", component: AdminShowHero, meta: { layout: "admin" } },
        { path: "heroes", name: "admin-heroes", component: AdminHeroes, meta: { layout: "admin" } },
        /* COURSES */
        { path: "ceu-learning-centers/new", name: "admin-new-ceu-learning-center", component: AdminEditCourse, meta: { layout: "admin" } },
        { path: "ceu-learning-centers/:id/edit", name: "admin-edit-ceu-learning-center", component: AdminEditCourse, meta: { layout: "admin" } },
        { path: "ceu-learning-centers/:id", name: "admin-show-ceu-learning-center", component: AdminShowCourse, meta: { layout: "admin" } },
        { path: "ceu-learning-centers", name: "admin-ceu-learning-centers", component: AdminCourses, meta: { layout: "admin" } },
        /* Industry Insider */
        { path: "industry-insider/new", name: "admin-new-industry-insider", component: () => import("views/admin/industry-insider/edit"), meta: { layout: "admin" } },
        { path: "industry-insider/:id/edit", name: "admin-edit-industry-insider", component: () => import("views/admin/industry-insider/edit"), meta: { layout: "admin" } },
        { path: "industry-insider/:id", name: "admin-show-industry-insider", component: () => import("views/admin/industry-insider/show"), meta: { layout: "admin" } },
        { path: "industry-insider", name: "admin-industry-insider", component: () => import("views/admin/industry-insider"), meta: { layout: "admin" } },

        /* Building Product Showcase */
        { path: "building-product-showcase/new", name: "admin-new-building-product-showcase", component: () => import("views/admin/building-product-showcases/edit"), meta: { layout: "admin" } },
        { path: "building-product-showcase/:id/edit", name: "admin-edit-building-product-showcase", component: () => import("views/admin/building-product-showcases/edit"), meta: { layout: "admin" } },
        { path: "building-product-showcase/:id", name: "admin-show-building-product-showcase", component: () => import("views/admin/building-product-showcases/show"), meta: { layout: "admin" } },
        { path: "building-product-showcase", name: "admin-building-product-showcase", component: () => import("views/admin/building-product-showcases"), meta: { layout: "admin" } },
        /* MANUFACTURERS */
        { path: "manufacturers/new", name: "admin-new-manufacturer", component: AdminEditManufacturer, meta: { layout: "admin" } },
        { path: "manufacturers/:id/edit", name: "admin-edit-manufacturer", component: AdminEditManufacturer, meta: { layout: "admin" } },
        { path: "manufacturers/:id", name: "admin-show-manufacturer", component: AdminShowManufacturer, meta: { layout: "admin" } },
        { path: "manufacturers", name: "admin-manufacturers", component: AdminManufacturers, meta: { layout: "admin" } },
        /* NEWS-ENTRIES */
        { path: "news-entries/new", name: "admin-new-news-entry", component: AdminEditNewsEntry, meta: { layout: "admin" } },
        { path: "news-entries/:id/edit", name: "admin-edit-news-entry", component: AdminEditNewsEntry, meta: { layout: "admin" } },
        { path: "news-entries/:id", name: "admin-show-news-entry", component: AdminShowNewsEntry, meta: { layout: "admin" } },
        { path: "news-entries", name: "admin-news-entries", component: AdminNewsEntries, meta: { layout: "admin" } },
        /* PRODUCT-TYPES */
        { path: "product-types/new", name: "admin-new-product-type", component: AdminEditProductType, meta: { layout: "admin" } },
        { path: "product-types/:id/edit", name: "admin-edit-product-type", component: AdminEditProductType, meta: { layout: "admin" } },
        { path: "product-types/:id", name: "admin-show-product-type", component: AdminShowProductType, meta: { layout: "admin" } },
        { path: "product-types", name: "admin-product-types", component: AdminProductTypes, meta: { layout: "admin" } },
        // /* PRICES */
        // { path: "prices/new", name: "admin-new-price", component: () => import("views/admin/prices/edit"), meta: { layout: "admin" } },
        // { path: "prices/:id/edit", name: "admin-edit-price", component: () => import("views/admin/prices/edit"), meta: { layout: "admin" } },
        // { path: "prices/:id", name: "admin-show-price", component: () => import("views/admin/prices/show"), meta: { layout: "admin" } },
        // { path: "prices", name: "admin-prices", component: () => import("views/admin/prices"), meta: { layout: "admin" } },
        /* PRODUCTS */
        // { path: "products/new", name: "admin-new-product", component: () => import("views/admin/products/edit"), meta: { layout: "admin" } },
        // { path: "products/:id/edit", name: "admin-edit-product", component: () => import("views/admin/products/edit"), meta: { layout: "admin" } },
        // { path: "products/:id", name: "admin-show-product", component: () => import("views/admin/products/show"), meta: { layout: "admin" } },
        // { path: "products", name: "admin-products", component: () => import("views/admin/products"), meta: { layout: "admin" } },
        /* REVIEWS */
        { path: "reviews/new", name: "admin-new-review", component: () => import("views/admin/reviews/edit"), meta: { layout: "admin" } },
        { path: "reviews/:id/edit", name: "admin-edit-review", component: () => import("views/admin/reviews/edit"), meta: { layout: "admin" } },
        { path: "reviews/:id", name: "admin-show-review", component: () => import("views/admin/reviews/show"), meta: { layout: "admin" } },
        { path: "reviews", name: "admin-reviews", component: () => import("views/admin/reviews"), meta: { layout: "admin" } },

        /* Claim Request */
        { path: "claim_requests/:id", name: "admin-show-claim-request", component: () => import("views/admin/claim-requests/show"), meta: { layout: "admin" } },
        { path: "claim_requests", name: "admin-claim-requests", component: () => import("views/admin/claim-requests"), meta: { layout: "admin" } },

        /* SERVICES */
        { path: "services/new", name: "admin-new-service", component: () => import("views/admin/services/edit"), meta: { layout: "admin" } },
        { path: "services/:id/edit", name: "admin-edit-service", component: () => import("views/admin/services/edit"), meta: { layout: "admin" } },
        { path: "services/:id", name: "admin-show-service", component: () => import("views/admin/services/show"), meta: { layout: "admin" } },
        { path: "services", name: "admin-services", component: () => import("views/admin/services"), meta: { layout: "admin" } },
        /* USERS */
        { path: "users/new", name: "admin-new-user", component: AdminEditUser, meta: { layout: "admin" } },
        { path: "users/:id/edit", name: "admin-edit-user", component: AdminEditUser, meta: { layout: "admin" } },
        { path: "users/:id", name: "admin-show-user", component: AdminShowUser, meta: { layout: "admin" } },
        { path: "users", name: "admin-users", component: AdminUsers, meta: { layout: "admin" } },
        /* VIDEOS */
        { path: "videos/new", name: "admin-new-video", component: AdminEditVideo, meta: { layout: "admin" } },
        { path: "videos/:id/edit", name: "admin-edit-video", component: AdminEditVideo, meta: { layout: "admin" } },
        { path: "videos/:id", name: "admin-show-video", component: AdminShowVideo, meta: { layout: "admin" } },
        { path: "videos", name: "admin-videos", component: AdminVideos, meta: { layout: "admin" } },

        /* Stories */
        { path: "event-issues/new", name: "admin-new-event-issue", component: AdminEditEventIssue, meta: { layout: "admin" } },
        { path: "event-issues/:id/edit", name: "admin-edit-event-issue", component: AdminEditEventIssue, meta: { layout: "admin" } },
        { path: "event-issues/:id", name: "admin-show-event-issue", component: AdminShowEventIssue, meta: { layout: "admin" } },
        { path: "event-issues", name: "admin-event-issues", component: AdminEventIssues, meta: { layout: "admin" } },
        /* Event Stories */
        { path: "event-stories/new", name: "admin-new-event-story", component: AdminEditEventStory, meta: { layout: "admin" } },
        { path: "event-stories/:id/edit", name: "admin-edit-event-story", component: AdminEditEventStory, meta: { layout: "admin" } },
        { path: "event-stories/:id", name: "admin-show-event-story", component: AdminShowEventStory, meta: { layout: "admin" } },
        { path: "event-stories", name: "admin-event-stories", component: AdminEventStories, meta: { layout: "admin" } },

        /* Events */
        { path: "events/new", name: "admin-new-event", component: AdminEditEvent, meta: { layout: "admin" } },
        { path: "events/:id/edit", name: "admin-edit-event", component: AdminEditEvent, meta: { layout: "admin" } },
        { path: "events/:id", name: "admin-show-event", component: AdminShowEvent, meta: { layout: "admin" } },
        { path: "events", name: "admin-events", component: AdminEvent, meta: { layout: "admin" } },

        /* Newsletter Signups */
        { path: "newsletter-signups/new", name: "admin-new-newsletter-signup", component: AdminEditNewsletterSubscription, meta: { layout: "admin" } },
        { path: "newsletter-signups/:id/edit", name: "admin-edit-newsletter-signup", component: AdminEditNewsletterSubscription, meta: { layout: "admin" } },
        { path: "newsletter-signups/:id", name: "admin-show-newsletter-signup", component: AdminShowNewsletterSubscription, meta: { layout: "admin" } },
        { path: "newsletter-signups", name: "admin-newsletter-signups", component: AdminNewsletterSubscription, meta: { layout: "admin" } },
    ] },
    { path: "/admin/sign-in", name: "admin-sign-in", component: AdminSignIn, meta: { layout: "auth" } },

    /* FRONTEND */
    { path: "/aec-continuing-education", name: "learning-center", component: () => import("views/courses"), meta: { title: "CEU Learning Center - designguide.com" } },
    { path: "/aec-news/:year?/:month?", name: "news", component: () => import("views/news"), meta: { title: "AEC News - designguide.com" } },
    { path: "/aec-news-awards/:year?/:month?", name: "industry-news", component: () => import("views/industry-news"), meta: { title: "Industry Insider" } },
    { path: "/innovations/:year?/:month?", name: "innovations", component: () => import("views/innovations"), meta: { title: "Innovations" } },
    { path: "/firm-profile/:id", name: "firm-profile", component: () => import("views/firm-profile") },
    { path: "/products-old/:code", name: "products-old-code", component: () => import("views/products-old"), props: true},
    { path: "/products-old", name: "products-old", component: () => import("views/products-old")},
    /* SETTINGS */
    { path: "/settings", name: "settings", component: () => import("views/settings"), meta: { requiresAuth: true, meta: { title: "Settings" } }, children: [
        { path: "account", name: "settings-account", component: () => import("views/settings/account") },
        { path: "change-password", name: "settings-change-password", component: () => import("views/settings/change-password") },
        { path: "applications", name: "settings-applications", component: () => import("views/settings/applications"), children: [
            { path: "firm", name: "settings-applications-firm", component: () => import("views/settings/applications/firm") },
            { path: "firm/:id/plan-settings", name: "settings-applications-firm-plan-settings", component: () => import("views/settings/applications/firms/plan/settings")},
            { path: "firm/:id/change-plan", name: "settings-applications-firm-change-plan", component: () => import("views/settings/applications/firms/plan/change")},
            // { path: "firm/:id/change-plan-confirmation", name: "settings-applications-firm-change-plan-confirmation", component: () => import("views/settings/applications/firms/plan/change-confirmation")},
            { path: "firm/:id/change-payment-method", name: "settings-applications-firm-change-payment-method", component: () => import("views/settings/applications/firms/payment-method/change")},
            { path: "manufacturer", name: "settings-applications-manufacturer", component: () => import("views/settings/applications/manufacturer") },
            { path: "manufacturer/:id/plan-settings", name: "settings-applications-manufacturer-plan-settings", component: () => import("views/settings/applications/manufacturers/plan/settings")},
            { path: "manufacturer/:id/change-plan", name: "settings-applications-manufacturer-change-plan", component: () => import("views/settings/applications/manufacturers/plan/change")},
            // { path: "manufacturer/:id/change-plan-confirmation", name: "settings-applications-manufacturer-change-plan-confirmation", component: () => import("views/settings/applications/manufacturers/plan/change-confirmation")},
            // { path: "manufacturer/:id/change-payment-method", name: "settings-applications-manufacturer-change-payment-method", component: () => import("views/manufacturer/application/change-payment-method")},
            { path: "manufacturer/:id/change-payment-method", name: "settings-applications-manufacturer-change-payment-method", component: () => import("views/settings/applications/manufacturers/payment-method/change")},
        ] },
        { path: "subscription", name: "settings-subscription", component: () => import("views/settings/subscriptions"), children: [
            { path: "plan", name: "settings-subscription-plan", component: () => import("views/settings/subscriptions/plan") },
            { path: "change-plan", name: "settings-subscription-change-plan", component: () => import("views/settings/subscriptions/plan/change") },
            { path: "change-payment-method", name: "settings-subscription-change-payment-method", component: () => import("views/settings/subscriptions/payment-method/change") },
            // { path: "change-confirmation", name: "settings-subscription-change-confirmation-plan", component: () => import("views/settings/subscriptions/plan/change-confirmation") },
            { path: "payment", name: "settings-subscription-payment", component: () => import("views/settings/subscriptions/payment") },
        ] },
        // { path: "job-opportunities", name: "settings-job-opportunities", component: () => import("views/settings/job_opportunities/index") },
        { path: "contact-us", name: "settings-contact-us", component: () => import("views/settings/contact-us") },
    ] },
    { path: "/choose-a-plan", name: "choose-plan", component: () => import("views/choose-plan"), meta: { title: "Choose plan" } },
    { path: "/:member/checkout/:after_response", name: "after-successful-checkout", component: () => import("views/payments/after_checkout"), meta: { title: "Membership Succeed - Designguide.com" } },
    // { path: "/member-after-successful-checkout", name: "member-after-successful-checkout", component: () => import("views/payments/after_checkout/member/success"), meta: { title: "Membership Succeed - Designguide.com" } },
    { path: "/membership-options", name: "membership-options", component: () => import("views/membership-options"), meta: { title: "Membership Options - designguide.com" } },
    { path: "/become-a-member", name: "become-a-member", component: () => import("views/become-a-member"), meta: { title: "Become a member" } },
    { path: "/showcase-your-products", name: "showcase-your-products", component: () => import("views/showcase-your-products"), meta: { title: "Become a member" } },
    { path: "/agreement", name: "agreement", component: () => import("views/agreement"), meta: { title: "Agreement" } },
    { path: "/private-policy", name: "private-policy", component: () => import("views/private-policy"), meta: { title: "Privacy Policy" } },
    /* FAVORITES */
    { path: "/favorites", name: "favorites", component: () => import("views/favorites"), meta: { requiresAuth: true, title: "My Favorites", metaTags: [{ name: "description", content: "Favorites description" }] } },
    /* FIRMS */
    { path: "/building-design-firms", name: "design-firms", component: () => import("views/firms"), meta: { title: "Building Design Firms" } },
    { path: "/building-design-firms/:country", name: "design-firms-with-country", component: () => import("views/firms"), meta: { title: "Building Design Firms in [Country]" } },
    { path: "/building-design-firms/:country/:state", name: "design-firms-with-country-state", component: () => import("views/firms"), meta: { title: "Building Design Firms in [State_Province]" } },

    { path: "/architects", name: "design-firms-architects", component: () => import("views/firms"), meta: { title: "Architects - Building Design Firms" } },
    { path: "/architects/:country", name: "design-firms-architects-with-country", component: () => import("views/firms"), meta: { title: "Architects in [Country]" } },
    { path: "/architects/:country/:state", name: "design-firms-architects-with-country-state", component: () => import("views/firms"), meta: { title: "Architects in [State]" } },

    { path: "/interior-designers", name: "design-firms-interior-designers", component: () => import("views/firms"), meta: { title: "Interior Designers - Building Design Firms" } },
    { path: "/interior-designers/:country", name: "design-firms-interior-designers-with-country", component: () => import("views/firms"), meta: { title: "Interior Designers in [Country]" } },
    { path: "/interior-designers/:country/:state", name: "design-firms-interior-designers-with-country-state", component: () => import("views/firms"), meta: { title: "Interior Designers in [State]" } },
    
    { path: "/engineers", name: "design-firms-engineers", component: () => import("views/firms"), meta: { title: "Engineers - Building Design Firms" } },
    { path: "/engineers/:country", name: "design-firms-engineers-with-country", component: () => import("views/firms"), meta: { title: "Engineers in [Country]" } },
    { path: "/engineers/:country/:state", name: "design-firms-engineers-with-country-state", component: () => import("views/firms"), meta: { title: "Engineers in [State]" } },
    
    { path: "/landscape-architects", name: "design-firms-landscape-architects", component: () => import("views/firms"), meta: { title: "Landscape Architects - Building Design Firms" } },
    { path: "/landscape-architects/:country", name: "design-firms-landscape-architects-with-country", component: () => import("views/firms"), meta: { title: "Landscape Architects in [Country]" } },
    { path: "/landscape-architects/:country/:state", name: "design-firms-landscape-architects-with-country-state", component: () => import("views/firms"), meta: { title: "Landscape Architects in [State]" } },
    
    { path: "/acoustical-av", name: "design-firms-acoustical-theater", component: () => import("views/firms"), meta: { title: "Theatre + Acoustical + Audio Visual - Building Design Firms" } },
    { path: "/acoustical-av/:country", name: "design-firms-acoustical-theater-with-country", component: () => import("views/firms"), meta: { title: "Theatre + Acoustical + Audio Visual in [Country]" } },
    { path: "/acoustical-av/:country/:state", name: "design-firms-acoustical-theater-with-country-state", component: () => import("views/firms"), meta: { title: "Theatre + Acoustical + Audio Visual in [State]" } },
    
    { path: "/lighting-designers", name: "design-firms-lighting-designers", component: () => import("views/firms"), meta: { title: "Lighting Designers - Building Design Firms" } },
    { path: "/lighting-designers/:country", name: "design-firms-lighting-designers-with-country", component: () => import("views/firms"), meta: { title: "Lighting Designers in [Country]" } },
    { path: "/lighting-designers/:country/:state", name: "design-firms-lighting-designers-with-country-state", component: () => import("views/firms"), meta: { title: "Lighting Designers in [State]" } },
    // { path: "/design-firms/:id/preview", name: "design-firms-preview", component: () => import("views/firm/preview"), meta: { title: "Design Firm Preview" } },
    { path: "/application/firm/confirmation", name: "firm-application-confirmation", component: () => import("views/firm/application/confirmation"), meta: { title: "Firm Application" } },
    { path: "/application/firm/new", name: "new-firm-application", component: () => import("views/firm/application"), meta: { title: "Firm Application" } },
    { path: "/application/firm/:id/checkout", name: "checkout-firm-application", component: () => import("views/firm/application/checkout"), meta: { title: "Manufacturer Application" } },
    { path: "/application/firm/:id/updated", name: "firm-application-updated", component: () => import("views/firm/application/updated"), meta: { title: "Firm Application Updated" } },
    { path: "/application/firm/:id", name: "firm-application", component: () => import("views/firm/application"), meta: { title: "Firm Application", requiresAuth: true } },
    { path: "/application/firm/:id/upgrade-plan", name: "firm-application-upgrade-plan", component: () => import("views/firm/application/upgrade-plan"), meta: { title: "Firm Application Membership" } },
    { path: "/application/firm/:id/upgrade-success", name: "firm-application-upgrade-success", component: () => import("views/firm/application/upgrade-success"), meta: { title: "Firm Application Membership" } },
    
    { path: "/building-design-firms/:directoryType", name: "firm-directory", component: () => import("views/firm/directory"), meta: { title: "Firm Directory" } },
    /* MANUFACTURERS */
    // { path: "/manufacturers", name: "manufacturers", component: () => import("views/manufacturers"), meta: { title: "Manufacturers" } },
    { path: "/application/manufacturer/new", name: "new-manufacturer-application", component: () => import("views/manufacturer/application"), meta: { title: "Manufacturer Application" } },
    { path: "/application/manufacturer/:id/checkout", name: "checkout-manufacturer-application", component: () => import("views/manufacturer/application/checkout"), meta: { title: "Manufacturer Application" } },
    { path: "/application/manufacturer/:id/updated", name: "manufacturer-application-updated", component: () => import("views/manufacturer/application/updated"), meta: { title: "Manufacturer Application Updated" } },
    { path: "/application/manufacturer/:id", name: "manufacturer-application", component: () => import("views/manufacturer/application"), meta: { title: "Manufacturer Application", requiresAuth: true } },
    { path: "/application/manufacturer/:id/upgrade-plan", name: "manufacturer-application-upgrade-plan", component: () => import("views/manufacturer/application/upgrade-plan"), meta: { title: "Manufacturer Application Membership" } },
    { path: "/application/manufacturer/:id/upgrade-success", name: "manufacturer-application-upgrade-success", component: () => import("views/manufacturer/application/upgrade-success"), meta: { title: "Manufacturer Application Membership" } },
    

    // { path: "/product-directory/:directoryType", name: "manufacturer-directory", component: () => import("views/manufacturer/directory"), meta: { title: "Manufacturer Directory" } },
    /* VIDEOS */
    { path: "/videos/:id", name: "show-video", component: () => import("views/video"), meta: { title: "Video" } },
    { path: "/building-design-videos", name: "videos", component: () => import("views/videos"), meta: { title: "AEC Videos - designguide.com" } },
    { path: "/aec-industry-events-expos", name: "events", component: () => import("views/events"), meta: { title: "AEC Events Expos - designguide.com" } },

    { path: "/building-product-showcase", name: "building-product-showcase", component: () => import("views/building_product/index"), meta: { title: "Building Product Showcase - designguide.com" } },

    { path: '/sign_in', name: 'sign-in', component: () => import('views/sign-in') },
    // { path: '/sign_up', name: 'sign-up', component: SignUpUser, meta: { loginLayout: true } },
    { path: '/users/:id/reset-password/:password_reset_token', name: 'user-reset-password', component: () => import("views/users/reset-password") },
    { path: '/admins/:id/reset-password/:password_reset_token', name: 'admin-reset-password', component: () => import("views/admins/reset-password"), meta: { layout: "auth" } },
    { path: "/manufacturerz/by-name", name: "manufacturerz-by-name", component: () => import("views/manufacturerz"), props: { tab: "by-name" } },
    { path: "/manufacturerz/csi-index", name: "manufacturerz-by-csi-index", component: () => import("views/manufacturerz"), props: { tab: "by-csi-index" } },
    { path: "/manufacturerz/product-types", name: "manufacturerz-by-product-types", component: () => import("views/manufacturerz"), props: { tab: "by-product-types" } },
    { path: "/manufacturerz", name: "manufacturerz", component: () => import("views/manufacturerz") },
    // { path: "/manufacturers/:directoryType", name: "products-directory", component: () => import("views/products"), meta: { title: "Building Products Directory" } },
    { path: "/building-products", name: "products-directory-index", component: () => import("views/products/index"), meta: { title: "Building Product Manufacturers" } },
    { path: "/csi-masterformat-index", name: 'product-csi-masterformat-index-page', component: () => import("views/products/redirect_to_pages") },
    { path: "/building-products/all-types", name: 'product-all-types-page', component: () => import("views/products/redirect_to_pages") },
    { path: "/building-products/company-name", name: 'product-company-name-page', component: () => import("views/products/redirect_to_pages") },
    { path: "/building-products/leed", name: 'product-leed-page', component: () => import("views/products/redirect_to_pages") },
    { path: "/csi-building-products/:division/:subdivision?", name: 'product-division-page', component: () => import("views/products/redirect_to_pages"), meta: { title: '[CSI-Index] [CSI-Subdivision Name] - CSI Masterformat Index' } },
    { path: "/:id", name: 'product-page', component: () => import("views/product-profile") },
    // { path: "/manufacturers/:id", name: 'product-page', component: () => import("views/product-profile") },
    // { path: "/manufacturers/subdivisions", name: "products-directory-subdivisions", component: () => import("views/products/subdivisions"), meta: { title: "Building Products Directory" } },
    // { path: "/manufacturers/type", name: "products-directory-type", component: () => import("views/products/type"), meta: { title: "Building Products Directory" } },
    // { path: "/manufacturers/name", name: "products-directory-name", component: () => import("views/products/name"), meta: { title: "Building Products Directory" } },
    { path: "*", component: () => import("views/not-found"), meta: { title: "Not Found" } }
]

const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (to.path == '/firm-directory/name'){
            return
        }
        else if (to.path == '/manufacturer-directory/name'){
            return
        }
        return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
    // Serve title and meta tags

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        // document.title = "DesignGuide | " + nearestWithTitle.meta.title
        let title = nearestWithTitle.meta.title
        let toParams = to.params;
        if(title.includes('[State]')){
            title = title.replace('[State]',toParams.state).replace('[Country]',toParams.country)
        }else if(title.includes('[Country]')){
            title = title.replace('[Country]',toParams.country)
        }

        document.title = title
        // document.title = "DesignGuide"
    } else if (previousNearestWithMeta) {
        // document.title = "DesignGuide | " + previousNearestWithMeta.meta.title
        document.title = previousNearestWithMeta.meta.title
        // document.title = "DesignGuide"
    } else {
        document.title = "DesignGuide"
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(el => el.parentNode.removeChild(el))

    // Skip rendering meta tags if there are none.
    if (nearestWithMeta) {
        // Turn the meta tag definitions into actual elements in the head.
        nearestWithMeta.meta.metaTags.map(tagDef => {
            const tag = document.createElement("meta")

            Object.keys(tagDef).forEach(key => {
                tag.setAttribute(key, tagDef[key])
            })

            // We use this to track which meta tags we create so we don't interfere with other ones.
            tag.setAttribute("data-vue-router-controlled", "")

            return tag
        })
            // Add the meta tags to the document head.
            .forEach(tag => document.head.appendChild(tag))
    }

    // Serve authorization
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters["account/isAuthorized"]) {
            let queries = { redirect: to.fullPath }
            if(to.name == 'manufacturer-application'){
                queries.type = 'manufacturer_admin'
            }else if(to.name === 'firm-application'){
                queries.type = 'firm_admin'
            }
            next({
                name: "sign-in",
                query: queries
            })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.requiresAdminAuth)) {
        if (!store.getters["account/isAdminAuthorized"]) {
            next({
                name: "admin-sign-in",
                query: { redirect: to.fullPath }
            })
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})

export default router
